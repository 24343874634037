<template>
  <b-card>
    <div class="custom-container">
      <form @submit.prevent="saveData" enctype="multipart/form-data">
        <div class="row">
          <div class="col-12">
            <card>
              <template v-slot:headerTitle>
                <vs-button
                  size="large"
                  transparent
                  class="m-0"
                  to="/helex/Medallas"
                >
                  <i class="fas fa-chevron-left mr-1"></i> Regresar a medallas
                </vs-button>
                <h2 class="card-title"><b>Nueva Medalla</b></h2>
              </template>
              <div class="row px-5 mt-4">
                <!-- nombre -->
                <div class="col-12 col-lg">
                  <div class="mb-3">
                    <label for="nombre" class="form-label">
                      <em class="text-danger font-weight-bolder"
                        >Descripcion/Nombre de la Medalla:</em
                      >
                    </label>
                    <input
                      required
                      v-model="data.descripcion"
                      placeholder="Escriba el Nombre o Descripcion de la medalla..."
                      type="text"
                      class="form-control"
                      id=" "
                    />
                  </div>
                </div>
                <!-- imagen -->
                <div class="col-12 col-lg">
                  <div class="mb-3">
                    <label for="imagen" class="form-label">
                      <em class="text-danger font-weight-bolder"
                        >Imagen de la Medalla:</em
                      >
                    </label>
                    <input
                      required
                      id="Imagen"
                      class="form-control"
                      type="file"
                      ref="fileInput"
                      @change="handleFileUpload"
                      accept="image/*"
                    />
                  </div>
                </div>
                <!-- btn -->
                <div class="col-12 d-flex justify-content-end mt-2">
                  <vs-button animation-type="vertical"   size="large">
                    Guardar Medalla
                    <template #animate>
                      <i class="fas fa-floppy-disk"></i
                    ></template>
                  </vs-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </form>
    </div>
  </b-card>
</template>

<script>
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      file: null,
      fileName: null,
      data: {
        descripcion: "",
        imagen: "",
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.$isLoading(false);
  },
  methods: {
    async saveData() {
      console.log("saving", this.data);
      await this.uploadImagenMedallaUrl();
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadImagenMedallaUrl() {
      try {
        this.$isLoading(true);
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let url = ".|site1|users|imageMedallas|" + this.usuario.id;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            console.log("archivo subido...", uploadImg);

            this.data.imagen =
              "https://www.helexiumfiles.com/users/imageMedallas/" +
              this.usuario.id +
              "/" +
              this.filename;

            try {
              await this.$store.dispatch("hl_post", {
                path: "Medallas/Create/",
                data: this.data,
              });
              Swal.fire({
                title: "Listo!",
                text: "Se ha guardado la medalla.",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                cancelButtonText: "Cancelar",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  this.$router.push("/helex/Medallas");
                }
              });
            } catch (error) {
              console.error(error);
            }
          }
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        Swal.fire(
          "Error!",
          "Ha ocurrido un error al guardar la medalla.",
          "error"
        );
        console.log("errror upload", error);
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      console.log("file", this.file);
    },
  },
};
</script>
